import {
  getTheme,
  IColumn,
  IDropdownOption,
  SelectionMode,
  ShimmeredDetailsList,
  ShimmerElementsGroup,
  ShimmerElementType,
} from '@fluentui/react';
import { DonutChart, HorizontalBarChart, IChartDataPoint, IChartProps } from '@fluentui/react-charting';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import { SeriesMapbubbleDataOptions, SeriesOptionsType, YAxisOptions } from 'highcharts';
import React from 'react';
import { IPubcenterPalette } from '../../../../../theme';
import { MediationReportingDataRequestBody } from '../../../../@data/store/schema/models/MediationReportingDataRequestBody';
import { EmptyChart } from '../../../../components/EmptyChart/EmptyChart';
import { CustomChart, CustomMapChart } from '../../../../components/HighCharts/CustomChart/CustomChart';
import { HighCharts } from '../../../../components/HighCharts/HighChartsLibrary';
import { IGridV2Column } from '../../../../layout/GridPageV2';
import { AuthenticationUtils } from '../../../../utils/AuthenticationUtils';
import { endOfDay, startOfDay } from '../../../../utils/DateUtils';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ReportsQueryStoreKeys } from '../../@data/store/schema/ReportsQueryStoreKeys';
import { getDataFromURL } from '../../@data/store/UrlQueryStore';
import { ZeroTotalChart } from '../../components/common/ZeroTotalChart/ZeroTotalChart';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { METRICS_COLORS } from '../../constants/metricsColors';
import { ratioMetrics } from '../../constants/ratioMetrics';
import ReportMessages from '../../Report.messages';
import { exportToCsv } from '../../utils/exportHelper';
import { FormatMessageFuncType, getReportColumnMetadataDisplayName } from '../../utils/intlHelper';
import { formatMetric, roundMetric } from '../../utils/metricsFormatter';
import { IMetadataElement, ReportingDataRow, TythonReportingDataRow } from '../@data/store/schema';
import { getReportPageStore } from '../@data/store/store';
import { IActiveFilter } from '../ReportDetailsPage/ActionBar/ActionBar';
import { ActiveMetrics, ChartGrain, ChartTypes, IChartsOptions } from './ReportCard.types';

const MAX_TOP_ROWS = 5;
const OTHERS_NAME = 'Others';
const theme = getTheme();
const palette = theme.palette as IPubcenterPalette;

export const getDonutChartShimmerCustomElements = () => {
  return (
    <div>
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.line, height: 20, width: '30%' },
          { type: ShimmerElementType.gap, height: 20, width: '70%' },
        ]}
      />
      <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 20, width: '100%' }]} />
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.gap, width: 'calc(100% - 75px)', height: 150 },
          { type: ShimmerElementType.circle, height: 150 },
          { type: ShimmerElementType.gap, width: 'calc(100% - 75px)', height: 150 },
        ]}
      />
      <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 10 }]} />
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.gap, width: 'calc(100% - 30px)', height: 10 },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '5%' },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, width: 'calc(100% - 30px)', height: 10 },
        ]}
      />
    </div>
  );
};
export const getHorizontalBarChartShimmerCustomElements = () => {
  return (
    <div>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '10%', height: 10, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '90%', height: 30 },
          { type: ShimmerElementType.line, width: '85%', height: 20, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '15%', height: 30 },
          { type: ShimmerElementType.line, width: '10%', height: 10, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '90%', height: 30 },
          { type: ShimmerElementType.line, width: '85%', height: 20, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '15%', height: 30 },
        ]}
      />
    </div>
  );
};
export const getDefaultShimmerCustomElements = () => {
  return (
    <div>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 30 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 30 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 30 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 30 },
        ]}
      />
    </div>
  );
};

export const getShimmerCustomElements = (chartType: ChartTypes) => {
  switch (chartType) {
    case ChartTypes.BAR:
      return getHorizontalBarChartShimmerCustomElements();
    case ChartTypes.DONUT:
      return getDonutChartShimmerCustomElements();
    case ChartTypes.LINE:
    case ChartTypes.MAP:
      return getDefaultShimmerCustomElements();
    case ChartTypes.GRID:
      return;
  }
};

export function mapToGridData(data: TythonReportingDataRow[], metric: string, mainDimension: string) {
  const items = data.map((item, index) => {
    return { [mainDimension]: item[mainDimension], [metric]: roundMetric(TythonColumnsEnum[metric], item[metric]) };
  });
  return items;
}

export function mapToDonutChartData(data: TythonReportingDataRow[], metric: IMetadataElement, mainDimension: IMetadataElement) {
  const points: IChartDataPoint[] = data.map((item, index) => {
    const colorIndex = getMetricColorIndex(TythonColumnsEnum[metric.key], index);

    return {
      legend: item[mainDimension.key],
      data: roundMetric(TythonColumnsEnum[metric.key], item[metric.key]),
      color: getGradientColor(TythonColumnsEnum[metric.key], colorIndex),
    };
  });

  return {
    chartTitle: 'Donut chart basic example',
    chartData: points,
  };
}
function getGradientColor(metricKey: TythonColumnsEnum, colorIndex: number): string {
  return getMetricColor(metricKey)?.gradients[colorIndex];
}
function getMainLegendColor(metricKey: TythonColumnsEnum): string {
  return getMetricColor(metricKey)?.main;
}

function getMetricColor(metricKey: TythonColumnsEnum): { main: string; gradients: string[] } {
  return METRICS_COLORS[metricKey] ?? METRICS_COLORS[TythonColumnsEnum.Revenue];
}

function getMetricColorIndex(metricKey: TythonColumnsEnum, index: number) {
  const metricColor = getMetricColor(metricKey);
  return metricColor ? ((index % metricColor.gradients.length) + metricColor.gradients.length) % metricColor.gradients.length : 0;
}
export function mapToBarChartData(
  data: TythonReportingDataRow[],
  total: number,
  metric: IMetadataElement,
  mainDimension: IMetadataElement,
  formatMessage: FormatMessageFuncType
): IChartProps[] {
  const bars = data.map((point, index) => {
    const barXData = roundMetric(TythonColumnsEnum[metric.key], point[metric.key]);
    const colorIndex = getMetricColorIndex(TythonColumnsEnum[metric.key], index);
    return {
      chartTitle: point[mainDimension.key],
      chartData: [
        {
          legend: point[mainDimension.key],
          horizontalBarChartdata: { x: barXData, y: total },
          color: getGradientColor(TythonColumnsEnum[metric.key], colorIndex),
          xAxisCalloutData: getReportColumnMetadataDisplayName(metric.key, formatMessage),
          yAxisCalloutData: barXData,
        },
      ],
    };
  });
  return bars;
}
export function mapToBubbleData(
  data: TythonReportingDataRow[],
  metric: IMetadataElement,
  mainDimension: IMetadataElement,
  codeDimensionKey: string,
  locale: string
): SeriesMapbubbleDataOptions[] {
  const bubbleData = data
    .map((row) => {
      const metricValue = roundMetric(TythonColumnsEnum[metric.key], row[metric.key]);
      const formattedMetric = formatMetric(TythonColumnsEnum[metric.key], row[metric.key], locale);

      return {
        name: row[mainDimension.key],
        code: row[codeDimensionKey],
        z: Number(metricValue),
        formattedValue: formattedMetric,
      };
    })
    .filter((item) => item.z > 0);
  return bubbleData;
}
export function mapToLineChartData(
  data: TythonReportingDataRow[],
  activeMetrics: ActiveMetrics,
  dateDimension: string,
  mainDimension?: IMetadataElement
) {
  const series: HighCharts.SeriesOptionsType[] = mainDimension?.key
    ? getTimeSeriesByDimension(data, mainDimension, dateDimension, activeMetrics)
    : getTimeSeriesByMetric(activeMetrics, data, dateDimension);
  return series;
}
function getTimeSeriesByDimension(
  data: TythonReportingDataRow[],
  mainDimension: IMetadataElement,
  dateDimension: string,
  activeMetrics: ActiveMetrics
) {
  const lineDataByDimension = {};
  for (const row of data) {
    if (!lineDataByDimension[row[mainDimension?.key]]) {
      lineDataByDimension[row[mainDimension?.key]] = [];
    }
    lineDataByDimension[row[mainDimension?.key]].push({ ...row });
  }

  const metric = Object.keys(activeMetrics).filter((activeMetric) => activeMetrics[activeMetric].isActive)[0];
  const accumulatedData = Object.values(lineDataByDimension).map((dataByDimension: TythonReportingDataRow[]) => {
    return dataByDimension.reduce((acc, curr) => {
      const sum = acc[metric] ? acc[metric] + Number(curr[metric]) : Number(curr[metric]);
      return {
        ...acc,
        [metric]: sum,
      };
    });
  });
  const topDataByMetric: string[] = getTopData(accumulatedData, metric, mainDimension?.key).topData.map((row) => row[mainDimension?.key]);
  const series: HighCharts.SeriesOptionsType[] = Object.keys(lineDataByDimension)
    .filter((key) => topDataByMetric.includes(key))
    .map((key, index) => {
      const colorIndex = getMetricColorIndex(TythonColumnsEnum[metric], index);
      const color = getGradientColor(TythonColumnsEnum[metric], colorIndex);
      return {
        name: key,
        type: 'line',
        data: lineDataByDimension[key]
          .sort((a, b) => {
            return a[dateDimension]! >= b[dateDimension]! ? 1 : -1;
          })
          .map((row) => {
            const localDate = new Date(row[dateDimension]!);
            const utcTimeStamp = Date.UTC(
              localDate.getFullYear(),
              localDate.getMonth(),
              localDate.getDate(),
              localDate.getHours(),
              localDate.getMinutes()
            );
            return [utcTimeStamp, roundMetric(TythonColumnsEnum[metric], Number(row[metric]))];
          }),
        yAxis: metric,
        color: color,
        tooltip: {
          pointFormatter: function () {
            const value = this.y || 0;
            return `<span style="color: ${this.series.color}">●</span> ${this.series.name}: ${HighCharts.numberFormat(
              value,
              value % 1 === 0 ? 0 : 2,
              '.',
              ','
            )}<br/>`;
          },
          useHTML: true,
        },
      };
    });
  return series;
}

function getTimeSeriesByMetric(
  activeMetrics: ActiveMetrics,
  data: TythonReportingDataRow[],
  dateDimension: string
): HighCharts.SeriesOptionsType[] {
  return Object.keys(activeMetrics)
    .filter((activeMetric) => activeMetrics[activeMetric].isActive)
    .map((metric) => {
      return {
        name: activeMetrics[metric].metricName,
        type: 'line',
        data: data
          .sort((a, b) => {
            return a[dateDimension]! >= b[dateDimension]! ? 1 : -1;
          })
          .map((row) => {
            const localDate = new Date(row[dateDimension]!);
            const utcTimeStamp = Date.UTC(
              localDate.getFullYear(),
              localDate.getMonth(),
              localDate.getDate(),
              localDate.getHours(),
              localDate.getMinutes()
            );
            return [utcTimeStamp, roundMetric(TythonColumnsEnum[metric], Number(row[metric]))];
          }),
        yAxis: metric,
        color: activeMetrics[metric].color,
        tooltip: {
          pointFormatter: function () {
            const value = this.y || 0;
            return `<span style="color: ${this.series.color}">●</span> ${this.series.name}: ${HighCharts.numberFormat(
              value,
              value % 1 === 0 ? 0 : 2,
              '.',
              ','
            )}<br/>`;
          },
          useHTML: true,
        },
      };
    });
}

export function getMetricDropdownOptions(
  metrics: IMetadataElement[],
  chartType: ChartTypes,
  formatMessage: FormatMessageFuncType
): IDropdownOption[] {
  return metrics
    .map((metric) => {
      return {
        key: metric.key,
        text: getReportColumnMetadataDisplayName(metric.key, formatMessage),
        title: getReportColumnMetadataDisplayName(metric.key, formatMessage),
      } as IDropdownOption;
    })
    .filter((metric) => !ratioMetrics.includes(String(metric.key)) || chartType === ChartTypes.MAP);
}
export const getChart = (
  {
    currentChartType,
    reportData,
    selectedMetric,
    mainDimension,
    chartComponentRef,
    mapChartComponentRef,
    activeMetrics,
    dateDimension,
    countryCodeDimension,
    formatMessage,
  }: IChartsOptions,
  locale: string
) => {
  const { topData, total } = getTopData(reportData, selectedMetric.key, mainDimension?.key);
  const roundedTotal = roundMetric(TythonColumnsEnum[selectedMetric.key], total);
  if (!reportData || reportData.length <= 0) {
    return (
      <EmptyChart
        noDataText={'No data available.'}
        noDataMessage={
          ' To update the tile, change the data range or select another metric. If the tile remains blank, your account might have no data.'
        }
        showGridLines={ChartTypes.LINE === currentChartType}
      />
    );
  }
  switch (currentChartType) {
    case ChartTypes.DONUT:
      if (!roundedTotal) {
        return <ZeroTotalChart />;
      }
      return (
        <DonutChart
          data={mapToDonutChartData(topData, selectedMetric, mainDimension!)}
          innerRadius={55}
          hideLegend={false}
          valueInsideDonut={formatMetric(TythonColumnsEnum[selectedMetric.key], total, locale)}
          enabledLegendsWrapLines={true}
          legendProps={{
            styles: {
              legend: {
                textTransform: 'none',
              },
            },
          }}
          styles={{
            legendContainer: {
              maxWidth: '100%',
            },
          }}
        />
      );
    case ChartTypes.BAR:
      if (!roundedTotal) {
        return <ZeroTotalChart />;
      }
      return <HorizontalBarChart data={mapToBarChartData(topData, total, selectedMetric, mainDimension!, formatMessage)} />;
    case ChartTypes.GRID:
      const gridColumns = buildGridColumns([selectedMetric, mainDimension!], formatMessage);
      return (
        <ShimmeredDetailsList
          items={mapToGridData(topData, selectedMetric.key, mainDimension?.key!)}
          columns={gridColumns || []}
          shimmerLines={MAX_TOP_ROWS + 1}
          enableShimmer={!reportData}
          selectionMode={SelectionMode.none}
        />
      );
    case ChartTypes.LINE:
      const lineChartSeries = mapToLineChartData(reportData, activeMetrics, dateDimension, mainDimension);
      const yAxes: YAxisOptions[] = buildYAxes(activeMetrics);
      return (
        <div>
          <CustomChart
            chartComponentRef={chartComponentRef}
            options={{
              series: lineChartSeries,
              legend: {
                enabled: true,
              },
              yAxis: yAxes,
              lang: {
                thousandsSep: ',',
              },
            }}
            dataMetric={''}
          />
        </div>
      );
    case ChartTypes.MAP:
      const data = mapToBubbleData(reportData, selectedMetric, mainDimension!, countryCodeDimension!, locale);
      const bubbleSeries: SeriesOptionsType[] = [
        {
          type: 'map',
          name: getReportColumnMetadataDisplayName(selectedMetric.key, formatMessage),
          enableMouseTracking: false,
          id: `${selectedMetric.key}-map`,
          nullColor: palette.neutralTertiaryAlt,
          borderColor: palette.neutralTertiaryAlt,
        },
        {
          type: 'mapbubble',
          name: getReportColumnMetadataDisplayName(selectedMetric.key, formatMessage),
          joinBy: ['iso-a2', 'code'],
          data: data,
          tooltip: {
            pointFormat: '{point.name}: {point.formattedValue}',
          },
          id: selectedMetric.key,
          color: getMainLegendColor(TythonColumnsEnum[selectedMetric.key]),
        },
      ];
      return (
        <div>
          <CustomMapChart
            chartComponentRef={mapChartComponentRef}
            options={{
              chart: {
                map: mapDataWorld,
              },
              legend: {
                enabled: false,
              },
              title: {
                text: '',
              },
              mapNavigation: {
                enabled: true,
                buttonOptions: {
                  verticalAlign: 'bottom',
                },
              },
              series: bubbleSeries,
            }}
            dataMetric={''}
          />
        </div>
      );
  }
};
export function getTopData(
  reportData: TythonReportingDataRow[],
  selectedMetric: string,
  mainDimension: string | undefined
): { topData: TythonReportingDataRow[]; total: number } {
  const topCount = MAX_TOP_ROWS;

  const topData: TythonReportingDataRow[] = [];
  const others: TythonReportingDataRow = {
    DateTime: '',
    [selectedMetric]: 0,
    className: '',
    apiPath: '',
  };
  let total = 0;
  if (mainDimension) {
    others[mainDimension] = OTHERS_NAME;
    const sortedData = reportData.sort((a, b) => {
      return a[selectedMetric] <= b[selectedMetric] ? 1 : -1;
    });
    let count = 0;
    for (const row of sortedData) {
      if (count >= topCount) {
        others[selectedMetric] += row[selectedMetric];
      } else {
        topData.push(row);
      }
      total += row[selectedMetric];
      count++;
    }
    if (count > topCount) {
      topData.push(others);
    }
  }
  return { topData, total };
}

function buildYAxes(activeMetrics: ActiveMetrics) {
  const enabledMetrics = Object.keys(activeMetrics).filter((activeMetric) => activeMetrics[activeMetric].isActive);
  let hasLeftAxis = false;
  const yAxes: YAxisOptions[] = Object.keys(activeMetrics).map((activeMetric) => {
    const newAxis = {
      title: {
        text: null,
      },
      opposite: hasLeftAxis,
      labels: {
        enabled: enabledMetrics.length <= 2,
      },
      id: activeMetric,
    };
    if (activeMetrics[activeMetric].isActive && enabledMetrics.length <= 2) {
      hasLeftAxis = true;
    }
    return newAxis;
  });
  return yAxes;
}
export function buildGridColumns(columns: IMetadataElement[], formatMessage: FormatMessageFuncType): IColumn[] {
  return columns.map((column) => {
    return {
      key: column.key,
      name: getReportColumnMetadataDisplayName(column.key, formatMessage),
      minWidth: 150,
      maxWidth: 300,
      fieldName: column.key,
      isResizable: true,
    };
  });
}
export function buildGridV2Columns(
  dimensions: IMetadataElement[],
  metrics: IMetadataElement[],
  defaultSelected,
  formatMessage: FormatMessageFuncType
): IGridV2Column[] {
  const columns = [...dimensions.sort(sortByDefaultColumns(defaultSelected)), ...metrics.sort(sortByDefaultColumns(defaultSelected))];
  return columns.map((column, index) => {
    return {
      key: column.key,
      name: getReportColumnMetadataDisplayName(column.key, formatMessage),
      minWidth: 150,
      fieldName: column.key,
      isSearchable: true,
      isVisible:
        defaultSelected.includes(column.key as TythonColumnsEnum) || !!dimensions.find((dimension) => column.key === dimension.key),
      isExportable: false,
      isResizable: true,
      suppressSorting: false,
      columnOrder: index,
      sortFunction:
        column.key === TythonColumnsEnum.DateTime || column.key === TythonColumnsEnum.Date
          ? /* tslint:disable-next-line */
            (a: any, b: any, isSortingDescending: boolean) => {
              const convertDate = (date: string) => {
                const dateParts = date.match(/(\d+)\/(\d+)\/(\d+), (\d+) (AM|PM)/);
                if (dateParts) {
                  const month = parseInt(dateParts[1], 10);
                  const day = parseInt(dateParts[2], 10);
                  const year = parseInt(dateParts[3], 10);
                  let hours = parseInt(dateParts[4], 10);
                  const ampm = dateParts[5];

                  if (ampm === 'PM' && hours < 12) {
                    hours += 12;
                  } else if (ampm === 'AM' && hours === 12) {
                    hours = 0;
                  }

                  return new Date(year, month - 1, day, hours, 0, 0);
                }
                return date;
              };

              const aDate = convertDate(a['DateTime']);
              const bDate = convertDate(b['DateTime']);

              return isSortingDescending ? +aDate - +bDate : +bDate - +aDate;
            }
          : undefined,
    };
  });
}
export function getActiveMarkers(
  metrics: IMetadataElement[],
  filters: IActiveFilter[],
  chartType: ChartTypes,
  defaultSelected: TythonColumnsEnum[],
  formatMessage: FormatMessageFuncType,
  selectedMetric: string = ''
): ActiveMetrics {
  const activeMetrics: ActiveMetrics = {};
  if (metrics && metrics.length > 0) {
    const sortedMetrics = metrics.sort(sortByDefaultColumns(defaultSelected));
    const isSelectedMetricDisabled = chartType !== ChartTypes.LINE && chartType !== ChartTypes.MAP && ratioMetrics.includes(selectedMetric);
    for (let i = 0; i < sortedMetrics.length; i++) {
      const metric = sortedMetrics[i];
      const appliedFiltersSet = new Set(filters.map((filter) => filter.key));
      let incompatibleFilter = '';
      const isIncompatible = metric.incompatibleFields?.some((incompatibleField) => {
        incompatibleFilter = appliedFiltersSet.has(incompatibleField) ? incompatibleField : '';
        return appliedFiltersSet.has(incompatibleField);
      });
      const incompatibleDimension = incompatibleFilter ? filters.find((filter) => filter.key === incompatibleFilter) : undefined;
      const DEFAULT_SHOWN_METRICS = 5;
      const isVisible = i < DEFAULT_SHOWN_METRICS;
      const isActive =
        selectedMetric && !isSelectedMetricDisabled
          ? metric.key === selectedMetric
          : defaultSelected.includes(metric.key as TythonColumnsEnum);
      const isDisabled = chartType !== ChartTypes.LINE && chartType !== ChartTypes.MAP && ratioMetrics.includes(String(metric.key));
      const inCompatibleMessage = getIncompatibleFilterMessage(
        getReportColumnMetadataDisplayName(metric.key, formatMessage),
        getReportColumnMetadataDisplayName(incompatibleDimension?.key || '', formatMessage) || ''
      );
      activeMetrics[metric.key] = {
        color: getMainLegendColor(TythonColumnsEnum[metric.key]),
        isActive: isActive && !isDisabled,
        metricData: '',
        metricName: getReportColumnMetadataDisplayName(metric.key, formatMessage),
        metricKey: metric.key,
        isVisible: isVisible || isActive,
        disabled: isDisabled,
        isIncompatible: isIncompatible,
        incompatibleTooltipCantText: formatMessage(inCompatibleMessage.cantMessage, inCompatibleMessage.params),
        incompatibleTooltipWontText: formatMessage(inCompatibleMessage.wontMessage, inCompatibleMessage.params),
      };
    }
  }

  return activeMetrics;
}
export function getIncompatibleFilterMessage(metrics: string, dimensions: string) {
  return {
    cantMessage: ReportMessages.chartIncompatibleFieldsWithFilterCantBeShown,
    wontMessage: ReportMessages.chartIncompatibleFieldsWithFilterWillNotBeShown,
    params: {
      metrics,
      dimensions,
    },
  };
}
export function getIncompatibleBreakdownMessage(metrics: string, dimensions: string) {
  return {
    message: ReportMessages.gridIncompatibleFieldsWhenBreakingDown,
    params: {
      metrics,
      dimensions,
    },
  };
}
export function getIncompatibleColumnWithBreakdownMessage(metrics: string, dimensions: string) {
  return {
    cantMessage: ReportMessages.gridIncompatibleFieldsWithBreakdown,
    wontMessage: ReportMessages.gridIncompatibleFieldsWhenAddingColumnWithBreakdown,
    params: {
      metrics,
      dimensions,
    },
  };
}
export function getIncompatibleColumnWithFilterMessage(metrics: string, dimensions: string) {
  return {
    cantMessage: ReportMessages.gridIncompatibleFieldsWithFilter,
    wontMessage: ReportMessages.gridIncompatibleFieldsWhenAddingColumnWithFilter,
    params: {
      metrics,
      dimensions,
    },
  };
}
export function getIncompatibleColumnWithBreakdownAndFilterMessage(metrics: string, dimensions: string) {
  return {
    cantMessage: ReportMessages.gridIncompatibleFieldsWithFilterAndBreakdown,
    wontMessage: ReportMessages.gridIncompatibleFieldsWhenAddingColumnWithFilterAndBreakdown,
    params: {
      metrics,
      dimensions,
    },
  };
}

function sortByDefaultColumns(defaultSelected: TythonColumnsEnum[]): ((a: IMetadataElement, b: IMetadataElement) => number) | undefined {
  return (a, b) => {
    return defaultSelected.indexOf(b.key as TythonColumnsEnum) - defaultSelected.indexOf(a.key as TythonColumnsEnum);
  };
}

export function constructRequestBody(
  title: string,
  dateRange: ICustomCalenderDateRange,
  currentChartGrain: ChartGrain,
  filters: IActiveFilter[] = [],
  breakdown: string[] = [],
  metrics: IMetadataElement[] = []
) {
  const callerGuid = AuthenticationUtils.createNewGuid();
  const { reportType } = getReportPageStore();

  return {
    callerGuid: callerGuid,
    reportName: title,
    reportType: reportType,
    dateTimeRanges: [
      {
        startDateTime: startOfDay(dateRange.startDate).toISOString(),
        endDateTime: endOfDay(dateRange.endDate).toISOString(),
      },
    ],
    grain: currentChartGrain,
    columns: [...breakdown, ...metrics.map((metric) => metric.key)],
    filters: filters.map((filter) => {
      return {
        Key: filter.filterKey,
        values: filter.values.map((value) => value.key),
      };
    }),
    requestOptions: {},
  };
}

export function constructMediationRequestBody(
  dateRange: ICustomCalenderDateRange,
  currentChartGrain: ChartGrain,
  filters: IActiveFilter[] = [],
  breakdown: string[] = [],
  columns: string[] = []
): MediationReportingDataRequestBody {
  return {
    startDate: startOfDay(dateRange.startDate).toISOString(),
    endDate: endOfDay(dateRange.endDate).toISOString(),
    grain: currentChartGrain,
    dimensions: [...breakdown],
    columns: columns,
    filters: filters.map((filter) => {
      return {
        Key: filter.filterKey,
        values: filter.values.map((value) => String(value.key)),
      };
    }),
  };
}
export function getStartingBreakdown(mainDimension?: string): string[] {
  let startingBreakdown = mainDimension ? [mainDimension] : [];
  const storedBreakdown = getDataFromURL<string[]>(ReportsQueryStoreKeys.BREAKDOWN);
  if (storedBreakdown) {
    startingBreakdown = storedBreakdown;
  }
  return startingBreakdown;
}

export function getStartingChartGrain(
  defaultChartGrain: ChartGrain | undefined,
  allowedChartGrains: IMetadataElement[],
  chartType: ChartTypes,
  chartGrainStoreKey: string
) {
  const defaultBasedOnChartType = chartType === ChartTypes.LINE ? ChartGrain.HOURLY : ChartGrain.NONE;
  let startingChartGrain: ChartGrain = defaultChartGrain ? defaultChartGrain : defaultBasedOnChartType;
  const storedChartGrain = getDataFromURL<ChartGrain>(chartGrainStoreKey);
  if (storedChartGrain && chartType === ChartTypes.LINE) {
    startingChartGrain = storedChartGrain;
  }
  return startingChartGrain;
}
export function exportReport(
  dataToExport: ReportingDataRow[],
  columnsMetadata: IMetadataElement[],
  filename: string,
  formatMessage: FormatMessageFuncType
) {
  if (dataToExport.length > 0) {
    const columnsToExport = columnsMetadata.filter((col) => dataToExport[0][col.key] !== undefined);
    exportToCsv(dataToExport, columnsToExport, filename, formatMessage);
  }
}
