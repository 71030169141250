import { getAppStore, handleError, onAuthLogout } from '..';
import { Log } from '../../../logging/src';
import { aadConfig } from '../../config/pubCenterAAD.config';
import { fetchPubCenterAADAccessTokenAsync } from '../services/PubCenterAADAuthService';
import { UserContext } from '../store/schema';
import { TokenType } from '../store/schema/enums/TokenType';

const tokenHeaderMap = {
  [TokenType.SessionGuid]: 'CommonSessionId',
  [TokenType.PubCenterAADToken]: 'PubCenterAuthenticationToken',
};

export async function buildRequestHeadersWithAuthToken(url: string, userContext?: UserContext): Promise<Headers> {
  if (!userContext) {
    userContext = getAppStore().userContext;
  }

  const defaultHeaders = [['content-type', 'application/json']];
  const tokenHeader = tokenHeaderMap[TokenType.PubCenterAADToken];
  const token = await fetchPubCenterAADAccessTokenAsync();

  if (userContext && token) {
    const headers = new Headers([...defaultHeaders, [tokenHeader, token]]);

    // Add custom headers for all mediation requests
    if (url.includes('preauth')) {
      headers.append('PublisherIds', getAppStore().publisher!.id.toString());
      headers.append('AccountIds', getAppStore().account!.id.toString());
    }

    headers.append('DevToken', aadConfig.auth.devToken.toString());

    return headers;
  } else {
    Log.error('User not logged in or authentication failed');
    onAuthLogout();

    return handleError(new Error('Invalid login, please login'), url);
  }
}
