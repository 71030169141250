export enum SearchRoute {
  all,
  adunits,
  adfilters,
  channels,
  publishers,
  reporting,
  properties,
  accounts,
  users
}
