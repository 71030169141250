export enum SecretQuestion {
  Anniversary = 'Anniversary',
  FatherMiddleName = 'FatherMiddleName',
  FavoriteMovie = 'FavoriteMovie',
  FavoritePetsName = 'FavoritePetsName',
  FavoriteSportsTeam = 'FavoriteSportsTeam',
  FavoriteTeacherName = 'FavoriteTeacherName',
  FirstChildMiddleName = 'FirstChildMiddleName',
  HighSchoolName = 'HighSchoolName',
  SpouseMiddleName = 'SpouseMiddleName'
}
