import { EntityType } from '../enums';
import { Model } from './Model';

export enum EntityEvaluationStatus {
  Pending = 'Pending',
  InReview = 'UnderConsideration',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum EntityEvaluationErrorCode {
  ProfileRejected = 'P001', // TST failure
  ContactRejected = 'P002', // OVet failure
  DomainRejected = 'D001',
  ReviewCaseFailed = 'R001', // ATS manual review failed
  Default = 'U001',
}

export interface IRejectionCode {
  RejectionCodes: EntityEvaluationErrorCode;
}

export class EvaluationStatus extends Model {
  public apiPath = undefined;
  public className = 'entityEvaluation';
  public entityId: number;
  public approvalStatus: EntityEvaluationStatus;
  public properties?: IRejectionCode;
  public entityType: EntityType;
}

export class PublisherEvaluationStatus extends EvaluationStatus {
  public entityType: EntityType.Publisher;
}

export class PropertyEvaluationStatus extends EvaluationStatus {
  public entityType: EntityType.Property;
}
