import { mutatorAction } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { EntityEvaluationStatus, getAppStore, Publisher, PublisherEvaluationStatus } from '../../../../@data';
import { getAdManagementStore } from '../store/store';

export const setPublisherEvaluationStatusAction = mutatorAction(
  'setPublisherEvaluationStatusAction',
  (publisherEvaluationStatus: PublisherEvaluationStatus) => {
    const appStore = getAppStore();
    appStore.publisherEvaluationStatus = publisherEvaluationStatus;
  }
);

export const setPublisherEvaluationFetchFailedAction = mutatorAction('setPublisherEvaluationFetchFailedAction', (isFailed: boolean) => {
  const appStore = getAppStore();
  appStore.isPublisherEvaluationStatusFetchFailed = isFailed;
});

export const setPublisherWarningMessageClosedAction = mutatorAction(
  'setPublisherWarningMessageClosedAction',
  (isPublisherWarningMessageClosed: boolean) => {
    const adManagementStore = getAdManagementStore();
    adManagementStore.isPublisherWarningMessageClosed = isPublisherWarningMessageClosed;
  }
);

export const setPublisherRejectedMessageClosedAction = mutatorAction(
  'setPublisherRejectedMessageClosedAction',
  (isPublisherRejectedMessageClosed: boolean) => {
    const adManagementStore = getAdManagementStore();
    adManagementStore.isPublisherRejectedMessageClosed = isPublisherRejectedMessageClosed;
  }
);

export const setPublisherEvalutionStatusToInReview = mutatorAction('setPublisherEvalutionStatusToInReview', (publisher: Publisher) => {
  Log.debug('Setting evaluation status to in review, when publisher profile is edited');
  const publisherEvaluationStatus: PublisherEvaluationStatus = new PublisherEvaluationStatus();
  publisherEvaluationStatus.entityId = publisher.id;
  publisherEvaluationStatus.approvalStatus = EntityEvaluationStatus.InReview;
  getAppStore().publisherEvaluationStatus = publisherEvaluationStatus;
});
