import { defineMessages } from 'react-intl';

export default defineMessages({
  profileTitle: {
    id: 'profile.title',
    defaultMessage: 'Profile',
  },
  profileHeading: {
    id: 'profile.heading',
    defaultMessage: 'Profile management',
  },
  profileDescription: {
    id: 'profile.description',
    defaultMessage: "You cannot manually update your profile at this time. Don't worry—you will be able to soon!",
  },
  profileSubtitle: {
    id: 'profile.subtitle',
    defaultMessage: 'COMING SOON',
  },
  profileIncomplete: {
    id: 'profile.incomplete',
    defaultMessage: 'Complete your profile; tell us more about you!',
  },
  manageProfile: {
    id: 'profile.manage',
    defaultMessage: 'Manage your profile',
  },
  underReviewTitle: {
    id: 'profile.underReviewTitle',
    defaultMessage: "We're reviewing your profile",
  },
  underReviewDescription: {
    id: 'profile.underReviewDescription',
    defaultMessage: "Our review can take up to seven business days, and we'll notify you as soon as it's approved.",
  },
  approvedTitle: {
    id: 'profile.approvedTitle',
    defaultMessage: 'Your account is now active',
  },
  approvedDescription: {
    id: 'profile.approvedDescription',
    defaultMessage: 'Congratulations! We successfully verified your profile and activated your account!',
  },
  retryTitle: {
    id: 'profile.retryTitle',
    defaultMessage: 'Your profile needs attention!',
  },
  retryDescription: {
    id: 'profile.retryDescription',
    defaultMessage:
      "Please make sure your profile details are valid. You'll have up to three attempts to submit your profile for verification.",
  },
  rejectedTitle: {
    id: 'profile.rejectedTitle',
    defaultMessage: "We weren't able to verify your identity",
  },
  rejectedDescriptionPart1: {
    id: 'profile.rejectedDescriptionPart1',
    defaultMessage: "Because your account doesn't comply with our",
  },
  rejectedDescriptionPart2: {
    id: 'profile.rejectedDescriptionPart2',
    defaultMessage: 'it has seven days before removal. If you have any questions or concerns regarding our decision, please contact',
  },
  rejectedBannerPart1: {
    id: 'profile.rejectedBannerPart1',
    defaultMessage: "We have rejected your profile because it doesn't comply with our",
  },
  rejectedBannerPart2: {
    id: 'profile.rejectedBannerPart2',
    defaultMessage: 'Your account will be removed in 7 days.',
  },
  termsAndConditions: {
    id: 'profile.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
  },
});
