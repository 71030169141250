import { ActionButton, Dropdown, Icon, Persona, PersonaPresence, PersonaSize } from '@fluentui/react';
import * as React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { getAppStore, getEntityAccessPermissions, isPublisherRejected, isTythonSelfServeUser, onAuthLogout } from '../../../../@data';
import { UserRoleEntity, completeAccess, hasReadAccess } from '../../../../@data/services/UserRoleService';
import { NavigationMenu } from '../../../../@data/store/schema/userrolemodels/NavigationMenu';
import { getSupportedLanguagesDropDownOptions } from '../../../../@data/utils/supportedLanguages';
import { RoutePrefix } from '../../../../Routes';
import { LOCAL_STORAGE_LOCALE } from '../../../../constants';
import { RouteName } from '../../../../pages/ad-management/@data';
import { onUpdateUserLanguage } from '../../../../pages/ad-management/Account/Settings/@data/actions';
import { injectRouterWithIntl } from '../../../../utils/ReactUtils';
import messages from './ProfilePanel.messages';
import { getButtonStyles, getClassNames, getDropdownStyles } from './ProfilePanel.styles';

export interface IProfileHeaderProps extends RouteComponentProps, InjectedIntlProps {
  hidePanel: () => void;
}

export const ProfilePanel = injectRouterWithIntl(
  class extends React.Component<IProfileHeaderProps> {
    public render(): JSX.Element {
      const userContext = getAppStore().userContext;
      const publisher = getAppStore().publisher;
      const localStorageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE);

      const { actionButtonsWrapper, dropDownWrapper, iconStyle } = getClassNames({});
      const buttonStyles = getButtonStyles();
      const dropdownStyles = getDropdownStyles();
      const signingUp: boolean = publisher === undefined && userContext?.id === undefined;

      const accessPermissions = getEntityAccessPermissions(UserRoleEntity.NavigationMenu) as NavigationMenu | null;
      const hasAccessToAuditHistory = hasReadAccess(accessPermissions ? accessPermissions.auditHistory : completeAccess);

      const name = userContext && userContext.name;
      const email = userContext && userContext.email;
      const { formatMessage } = this.props.intl;
      return (
        <React.Fragment>
          <Persona
            text={name}
            secondaryText={email}
            size={PersonaSize.size48}
            presence={PersonaPresence.online}
            showSecondaryText={true}
            hidePersonaDetails={false}
          />
          <div className={actionButtonsWrapper}>
            {isTythonSelfServeUser() && (
              <ActionButton
                iconProps={{ iconName: 'PlayerSettings' }}
                onClick={this._onUserSettingsButtonClicked}
                styles={buttonStyles}
                disabled={signingUp || isPublisherRejected()}
              >
                {formatMessage(messages.userSettings)}
              </ActionButton>
            )}
            {hasAccessToAuditHistory && (
              <ActionButton
                iconProps={{ iconName: 'ChangeEntitlements' }}
                styles={buttonStyles}
                onClick={this._onAuditHistoryButtonClicked}
                disabled={signingUp || isPublisherRejected()}
              >
                {formatMessage(messages.auditHistory)}
              </ActionButton>
            )}

            {!signingUp && isTythonSelfServeUser() && (
              <div className={dropDownWrapper}>
                <Icon iconName="LocaleLanguage" className={iconStyle} />
                <Dropdown
                  options={getSupportedLanguagesDropDownOptions(getAppStore().locale)}
                  onChange={this._onUserLanguageChanged}
                  selectedKey={localStorageLocale}
                  styles={dropdownStyles}
                  disabled={signingUp}
                  dropdownWidth="auto"
                />
              </div>
            )}

            <ActionButton iconProps={{ iconName: 'SignOut' }} onClick={() => onAuthLogout()} styles={buttonStyles} disabled={signingUp}>
              {formatMessage(messages.profileSignOut)}
            </ActionButton>
          </div>
        </React.Fragment>
      );
    }

    private _onUserSettingsButtonClicked = () => {
      this.props.hidePanel();
      const { publisher } = getAppStore();
      const settingsPageRoute = `/${RoutePrefix.adManagement}/${publisher ? publisher.id + '/' : ''}${RouteName[RouteName.userSettings]}`;
      this.props.history.push(settingsPageRoute);
    };

    private _onAuditHistoryButtonClicked = () => {
      this.props.hidePanel();
      const { publisher } = getAppStore();
      const auditHistoryPageRoute = `/${RoutePrefix.adManagement}/${publisher ? publisher.id + '/' : ''}${
        RouteName[RouteName.auditHistory]
      }`;
      this.props.history.push(auditHistoryPageRoute);
    };

    private _onUserLanguageChanged = (ev, option) => {
      const localStorageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE);
      if (option.key !== localStorageLocale) {
        onUpdateUserLanguage(option.key);
      }
    };
  }
);
