export function getScreenReaderTextStyles() {
  return {
    display: 'none',
  };
}

export const flexWithAlignCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const flexWithCenter = {
  ...flexWithAlignCenter,
  justifyContent: 'center',
};

export const flexWithJustifyBetween = {
  ...flexWithAlignCenter,
  justifyContent: 'space-between',
};

export const noPointerEvents = {
  pointerEvents: 'none',
};
