import { IProcessedStyleSet, Overlay, classNamesFunction, getTheme } from '@fluentui/react';
import * as React from 'react';
import { IAppState, getAppStore, isPublisherRejected, isTythonSelfServeUser } from '../../@data';
import AppMessages from '../../App.messages';
import { SupportPageRoute } from '../../Routes';
import { PageSpinner } from '../../components/Spinners';
import { LOCAL_STORAGE_LOCALE, TYTHON_DOMAIN_REDIRECTION_CACHE_KEY } from '../../constants/AppConstants';
import { useWindowSize } from '../../hooks';
import { getAdManagementStore } from '../../pages/ad-management/@data';
import '../../pages/ad-management/@data/actions';
import LoginPageMessages from '../../pages/login/LoginPage.messages';
import { RedirectionSpinner } from '../../pages/login/components/RedirectionSpinner';
import { injectIntlWithObserver } from '../../utils';
import { GlobalSearchHeader } from '../GlobalSearchHeader/GlobalSearchHeader';
import { getStyles } from './PageLayout.styles';
import { IPageLayoutProps, IPageLayoutStyleProps, IPageLayoutStyles } from './PageLayout.types';
const theme = getTheme();
const getClassNames = classNamesFunction<IPageLayoutStyleProps, IPageLayoutStyles>();

const { onWindowResize } = useWindowSize();
/**
 * PageLayout component is used to render the outermost layout of the application.
 * This layout renders the global search header, page level overlay spinner, and footer.
 */
export const Page = injectIntlWithObserver(
  class PageLayout extends React.Component<IPageLayoutProps> {
    private _classNames: IProcessedStyleSet<IPageLayoutStyles>;

    public handleResize = () => {
      onWindowResize(window);
    };

    public componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    public render(): JSX.Element | null {
      const appStore = getAppStore();
      this._classNames = getClassNames(getStyles, { theme });

      // Show spinner until publisher info is fetched from the backend
      if (appStore.loading && appStore.defaultPublisher !== 0 && !appStore.publisher) {
        return this._renderSpinner(appStore);
      }

      /** Do not render new UI content until publisher info fetched
       * Tython Publisher will be redirected to homePage
       * Non-Tython MSA user is redirected to the reporting dashboard
       */
      if (getAdManagementStore().doNotRenderDefaultPage) {
        return this._renderSpinner(appStore);
      }

      const localStorageLocal = localStorage.getItem(LOCAL_STORAGE_LOCALE);

      return (
        <div className={this._classNames.root}>
          {isPublisherRejected() && this.props.location.pathname !== SupportPageRoute && <Overlay style={{ zIndex: 9999 }} />}
          <div style={{ zIndex: isPublisherRejected() ? 99999 : undefined }}>
            <GlobalSearchHeader renderLeftMost={this.props.renderLeftMostSection} />
          </div>
          <div className={this._classNames.wrapper}>{this.props.children}</div>
          {(appStore.loading || (isTythonSelfServeUser() && !localStorageLocal)) &&
            this._renderSpinner(appStore, isTythonSelfServeUser() && !localStorageLocal)}
        </div>
      );
    }

    private _renderSpinner = (appStore: IAppState, renderWhiteBackground?: boolean): JSX.Element => {
      return sessionStorage.getItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY) === 'true' ? (
        <RedirectionSpinner label={this.props.intl.formatMessage(LoginPageMessages.redirectionMessage)} />
      ) : (
        <PageSpinner
          styleProps={{
            overlayStyle: renderWhiteBackground ? this._classNames.overlayStyle : undefined,
          }}
          label={this.props.intl.formatMessage(appStore.waiting ? AppMessages.loadingWaitingLabel : AppMessages.loadingLabel)}
        />
      );
    };
  }
);
