export const APP_NAME_PUBCENTER = 'pubCenter';
export const APP_NAME_NEW = 'Microsoft Monetize Now';
export const APP_NAME_SHORT = 'Monetize Now';
export const APP_NAME_NEW_COMPACT = 'MonetizeNow';
export const PUBCENTER_TITLE = 'PubCenter';

export const MAIN_CONTENT_ID = 'main-content';

// Used to change the copy button icon for 3000ms, indicating copy was successful
export const COPY_BUTTON_TIMEOUT = 3000;
// Used to dismiss notifications after 10s they show up
export const NOTIFICATION_DISMISS_TIMEOUT = 10000;
export const TOKEN_EXPIRED_SEARCH_PARAM_KEY = 'isTokenExpired';
export const CLARITY_HOME_WEB_APP = 'https://clarity.microsoft.com';
export const CLARITY_WEB_APP = 'https://clarity.microsoft.com/projects/view/{id}/settings';
export const CLARITY_PRIVACY_STATEMENT = 'https://privacy.microsoft.com/en-us/privacystatement';
export const CLARITY_DEMO = 'https://clarity.microsoft.com/demo/projects/view/3t0wlogvdz/dashboard?date=Last%203%20days';
export const CLARITY_PRIVACY_DISCLOSURE = 'https://learn.microsoft.com/en-us/clarity/setup-and-installation/privacy-disclosure';
export const WAITLIST_FORM =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR9CSFbF9q-VAlTdHTCCoFF5UNFdaMjJPVTY3RjBGVUpGMEdMTFVFOUlUMi4u';

export const USER_SIGNUP_KEY = 'isUserSignUpFlow';

export const DEFAULT_COUNTRY_NAME = 'United States';
export const DEFAULT_COUNTRY_CODE = 'US';
export const DEFAULT_CURRENCY_CODE = 'USD';
export const DEFAULT_LANGUAGE_CODE = 'en';
export const DEFAULT_LOCALE = 'en-US';

export const NO_VALUE_PLACEHOLDER = '-';

export const SESSION_NOTIFICATIONS_UI = 'UIGeneratedNotifications';
export const SESSION_DISMISSED_NOTIFICATIONS_BACKEND = 'BackendDismissedNotifications';
export const SUPPORT_EMAIL = 'pubhelp@microsoft.com';
export const TYTHON_SUPPORT_EMAIL = 'monetizenow@microsoft.com';

export const TNC_HREF_PATH = '/terms-and-conditions';

export const DEFAULT_PUBLISHER_CACHE_KEY = 'app.defaulftPublisherId';

export const DEFAULT_ADUNIT_IMAGE_ASPECT_RATIO = process.env.REACT_APP_DEFAULT_ADUNIT_IMAGE_ASPECT_RATIO ?? '1.91:1';

const DEFAULT_ADUNIT_IMAGE_ASPECT_RATIOS = ['1.91:1', '1:1', '4:3', '9:16'];
export const ADUNIT_IMAGE_ASPECT_RATIOS =
  process.env.REACT_APP_ADUNIT_IMAGE_ASPECT_RATIOS?.split(',') ?? DEFAULT_ADUNIT_IMAGE_ASPECT_RATIOS;

export const PRIVACY_STATEMENT = 'http://go.microsoft.com/fwlink/?LinkId=248681';

export const SUCCESS_MESSAGE_WIDTH = 480;
export const CUSTOM_WIDTH = '520px';
export const CUSTOM_NOTIFICATIONS_WIDTH = '460px';

export const PAGINATION_PAGE_SIZES = [10, 25, 50, 75, 100];

export const REDIRECT_URI_SESSION_STORAGE_KEY = 'redirect_uri';

export const TYTHON_DOMAIN_REDIRECTION_CACHE_KEY = 'isRedirectedUser';
export const DOMAIN_REDIRECTION_QUERY_PARAM = 'loginHint';

export const ADS_UI_URL = process.env.REACT_APP_ENV === 'production' ? 'https://ads.microsoft.com' : 'https://ads-int.microsoft.com';

export const ENTERPRISE_USER_ACCESS_URL = 'https://euaaccessportal.microsoft.com/request/access/Advertising_PubCenter';

export const WAITLIST_FORM_LINK =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR9CSFbF9q-VAlTdHTCCoFF5UNFdaMjJPVTY3RjBGVUpGMEdMTFVFOUlUMi4u';

export const ADS_DOT_TEXT_HELP_URL = 'https://help.pubcenter.microsoft.com/#apex/pcv4/en/07509/-1';
export const CONNECT_SITE_HELP_URL = 'https://help.ucm.microsoft.com/#apex/pcv4/en/07507/-1';
export const SITE_APPROVAL_HELP_URL = 'https://help.ads.microsoft.com/#apex/pcv4/en/07510/-1';

export const WELCOME_SEND_FLAG = 'welcomeEmailSend';
export const LOCAL_STORAGE_LOCALE = 'LOCAL_STORAGE_LOCALE';
export const UNAUTHORIZED_LOCAL_STORAGE_LOCALE = 'UNAUTHORIZED_LOCAL_STORAGE_LOCALE';

export const EN_LOCALE_KEY = 'en-us';
export const FR_LOCALE_KEY = 'fr-fr';
export const DE_LOCALE_KEY = 'de-de';
export const IT_LOCALE_KEY = 'it-it';
export const ES_LOCALE_KEY = 'es-es';
export const EN_GB_LOCALE_KEY = 'en-gb';
export const ES_MX_LOCALE_KEY = 'es-mx';
export const FR_CA_LOCALE_KEY = 'fr-ca';
