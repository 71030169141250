import { PublisherType } from '../store/schema/enums/PublisherType';
import { EntityEvaluationErrorCode, EntityEvaluationStatus } from '../store/schema/models/EntityEvaluationStatus';
import { getAppStore } from '../store/store';

export function getCurrentPublisher() {
  return getAppStore().publisher;
}

export function getCurrentPublisherId() {
  return getCurrentPublisher()?.id;
}

export function getCurrentPublisherName() {
  return getCurrentPublisher()?.name as string;
}

export function isPublisherProfileDataPresent() {
  return !!getCurrentPublisher()?.address;
}

export function getPublisherEvaluationStatus() {
  return getAppStore().publisherEvaluationStatus;
}

export function getPublisherCountry() {
  return getCurrentPublisher()?.address?.country;
}

export function getPublisherEvaluationApprovalStatus() {
  return getPublisherEvaluationStatus()?.approvalStatus;
}

export function isPublisherEvaluationStatusFetchFailed() {
  return getAppStore().isPublisherEvaluationStatusFetchFailed;
}

export function getPublisherEvaluationErrorCode() {
  return getPublisherEvaluationStatus()?.properties?.RejectionCodes;
}

export function isPublisherProfileInComplete() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Pending;
}

export function isPublisherApproved() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Approved;
}

// NOTE: Currently we are handling any rejection codes.
// If publisher EntityEvaluationStatus is Rejected, then publisher is shown as suspended in UI
export function isPublisherRejected() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Rejected;
}

// NOTE: This is not used anywhere for now but will be used soon after getting the product clarity.
export function isPublisherProfileRejected() {
  return isPublisherRejected() && getPublisherEvaluationErrorCode() === EntityEvaluationErrorCode.ProfileRejected;
}

// NOTE: This is not used anywhere for now but will be used soon after getting the product clarity.
export function isPublisherContactRejected() {
  return isPublisherRejected() && getPublisherEvaluationErrorCode() === EntityEvaluationErrorCode.ContactRejected;
}

// NOTE: This is not used anywhere for now but will be used soon after getting the product clarity.
export function isPublisherReviewCaseRejected() {
  return isPublisherRejected() && getPublisherEvaluationErrorCode() === EntityEvaluationErrorCode.ReviewCaseFailed;
}

// NOTE: This is not used anywhere for now but will be used soon after getting the product clarity.
export function isPublisherDomainRejected() {
  return isPublisherRejected() && getPublisherEvaluationErrorCode() === EntityEvaluationErrorCode.DomainRejected;
}

export function isPublisherUnderReview() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.InReview;
}

export function getPublisherAccountType() {
  return getCurrentPublisher()?.publisherType;
}

export function isIndividualPublisher() {
  return getPublisherAccountType() === PublisherType.individual;
}

export function isOrganizationPublisher() {
  return getPublisherAccountType() === PublisherType.organization;
}
