import React from 'react';
import { HomePageStatus } from '../../../@data/store/schema/IHomeViewState';
import { getClassNames } from '../SetupCard.styles';

export const getProgressBar = (steps?: number, status?: HomePageStatus): JSX.Element => {
  const { progressStepsContainerStyle, progressStepStyle } = getClassNames();

  // colors: notStarted:#D2D0CE awaitingForVerification:#0078D4 completed:#107C10
  const progressbarColor = status && HomePageStatus[status] === HomePageStatus.AwaitingVerification ? '#0078D4' : '#107C10';
  if (!steps) {
    return <></>;
  }
  const bars = [...Array(steps)].map((step, i) => {
    return (
      <div
        className={progressStepsContainerStyle}
        key={i}
        style={{
          background:
            (status && HomePageStatus[status] === HomePageStatus.AwaitingVerification && i <= steps - 2) ||
            (status && HomePageStatus[status] === HomePageStatus.Completed && i <= steps - 1)
              ? progressbarColor
              : undefined,
        }}
      />
    );
  });

  return <div className={progressStepStyle}>{bars}</div>;
};
