import { NotificationLabel, NotificationSeverity } from '../enums';
import { NotificationActionLink } from './NotificationActionLink';

export class Notification {
  public notificationTitle: string;
  public notificationContent: string;
  public notificationLabel: NotificationLabel;
  public notificationId: string;
  public severity: NotificationSeverity;
  public notificationActions: NotificationActionLink[];
  public lastModifiedDTime: Date;
  // tslint:disable-next-line: no-any
  public propertyBag: any;
}
