import { IButtonStyles } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';
import { IAdCreativePageStyleProps, IAdCreativePageStyles } from './AdCreativePage.types';

export const getStyles = (props: IAdCreativePageStyleProps): IAdCreativePageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return {
    root: {
      display: 'flex',
      height: 'inherit',
      flexDirection: 'column',
      flex: 1,
    },
    adSourceField: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    adSourceFields: {
      display: 'flex',
      borderTop: '1px solid #EFF6FC',
      borderBottom: '1px solid #EFF6FC',
      minHeight: spacing.size96,
    },
    adReview: {
      marginTop: spacing.size8,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    filterFields: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    searchBoxItem: {
      marginTop: '29px',
      width: '466px',
    },
    selectedClose: {
      display: 'block',
      height: spacing.size44,
      textAlign: 'center',
      lineHeight: spacing.size44,
      cursor: 'pointer',
    },
    cancelIcon: {
      marginTop: spacing.size4,
      marginLeft: -spacing.size8,
    },
    applyButton: {
      marginLeft: spacing.size8,
      marginRight: spacing.size8,
      marginBottom: spacing.size16,
      marginTop: '29px',
    },
    blockAdChannelButton: {
      display: 'flex',
      flexDirection: 'row',
    },
    imageList: {
      overflow: 'hidden',
      fontSize: 0,
      position: 'relative',
    },
    imageListTitle: {
      textAlign: 'center',
      outline: 'none',
      position: 'relative',
      float: 'left',
      background: theme.palette.neutralLighter,
      selectors: {
        'focus:after': {
          content: '',
          position: 'absolute',
          left: 2,
          right: 2,
          top: 2,
          bottom: 2,
          boxSizing: 'border-box',
          border: `1px solid ${theme.palette.white}`,
        },
      },
    },
    imageListSizer: {
      paddingBottom: '100%',
    },
    imageListPadder: {
      position: 'absolute',
      left: 2,
      top: 2,
      right: 2,
      bottom: 2,
    },
    imageListLabel: {
      background: 'rgba(0, 0, 0, 0.3)',
      color: '#FFFFFF',
      position: 'absolute',
      padding: 10,
      bottom: 0,
      left: 0,
      width: '100%',
      fontSize: theme.fonts.small.fontSize,
      boxSizing: 'border-box',
    },
    imageListImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    adBlockPanelListingBox: {
      minHeight: '200px',
      alignItems: 'center',
      display: 'flex',
      border: 'black',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    adBlockPanelListingPaginatedBox: {
      overflow: 'hidden',
      minHeight: '150px',
      margin: '4px',
    },
    adBlockPanelListingPaginatedLeftItems: {
      float: 'left',
      maxWidth: '70%',
    },
    adBlockPanelListingPaginatedRightItems: {
      float: 'right',
      maxWidth: '30%',
    },
    adBlockPanelListingLightText: {
      fontWeight: 'normal',
      fontSize: '80%',
    },
    adBlockPanelListingCenterLightText: {
      fontWeight: 'normal',
      fontSize: '80%',
      textAlign: 'center',
    },
    adBlockPanelListingLoadingBox: {
      display: 'block',
      width: '100%',
    },
    adBlockPanelListingNoneBox: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    panelButton: {
      display: 'flex',
      selectors: {
        '.ms-Button': {
          marginRight: spacing.size8,
        },
      },
    },
    subComponentStyles: {
      pivot: {
        itemContainer: {
          display: 'flex',
          height: 'inherit',
          flexDirection: 'column',
          flex: 1,
        },
      },
      formItem: {
        root: {
          marginLeft: spacing.size8,
          marginRight: spacing.size8,
          width: 'auto',
        },
      },
      formSection: {
        root: {
          width: 'auto',
        },
        content: {
          marginTop: '10px',
        },
      },
      searchBox: {
        root: {
          marginTop: 'auto',
          marginBottom: 'auto',
        },
      },
      filterPanel: {
        commands: {
          background: palette.white,
        },
        main: {
          width: spacing.size480,
          position: 'absolute',
        },
        header: {
          marginBottom: spacing.size24,
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
        },
        footer: {
          borderTopStyle: 'solid',
          borderColor: theme.palette.neutralLight,
          borderTopWidth: 1,
          background: palette.white,
        },
      },
    },
  };
};

export const iconButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: '#C7E0F4',
    marginRight: '8px',
  },
};
