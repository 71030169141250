import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { showErrorToastNotification } from '../../../../@data';
import { getCurrentPublisher, getPublisherEvaluationStatus } from '../../../../@data/selectors';
import messages from '../../Account/AccountPage.messages';
import { setPublisherEvaluationFetchFailedAction, setPublisherEvaluationStatusAction } from '../actions/entityEvaluationMutatorActions';
import { fetchPublisherEvaluationStatusAction } from '../actions/publisherEvaluationActions';
import { fetchPublisherEvaluationStatus } from '../services/publisherEvaluationService';

/**
 * This orchestrator is for the whole ad-management module as any page of the
 * ad-management module can trigger this action.
 */

orchestrator(fetchPublisherEvaluationStatusAction, async ({ cache }) => {
  try {
    const publisher = getCurrentPublisher();
    if (!publisher) {
      return;
    }

    if (cache && !!getPublisherEvaluationStatus()) {
      return;
    }

    const evaluationResponse = await fetchPublisherEvaluationStatus(publisher);
    setPublisherEvaluationStatusAction(evaluationResponse);
    setPublisherEvaluationFetchFailedAction(false);
  } catch (error) {
    setPublisherEvaluationFetchFailedAction(true);
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadPublisherStatus });
    Log.error(`Failed to fetch publisher status. Please retry after sometime.: ${error.message}}`);
  }
});
