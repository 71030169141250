import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'profilePage.title',
    defaultMessage: 'Profile',
  },
  submitSuccessTitle: {
    id: 'profilePage.submitSuccessTitle',
    defaultMessage: 'Thanks for completing your profile',
  },
  submitSuccessMessage: {
    id: 'profilePage.submitSuccessMessage',
    defaultMessage: "We are reviewing your profile. This can take up to 7 business days and we'll notify you as soon as it's approved.",
  },
  submitSuccessButtonLabel: {
    id: 'profilePage.submitSuccessButtonLabel',
    defaultMessage: 'Go to dashboard',
  },
  readOnlyPageTooltip: {
    id: 'profilePage.readOnlyPageTooltip',
    defaultMessage: "You can only edit your profile after we've completed our review of your profile information.",
  },
  addressLine1: {
    id: 'primaryContact.addressLine1',
    defaultMessage: 'Address line 1',
  },
  addressLine2: {
    id: 'primaryContact.addressLine2',
    defaultMessage: 'Address line 2',
  },
  city: {
    id: 'primaryContact.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'primaryContact.state',
    defaultMessage: 'State',
  },
  zipCode: {
    id: 'primaryContact.zipCode',
    defaultMessage: 'ZIP code',
  },
  country: {
    id: 'primaryContact.country',
    defaultMessage: 'Country',
  },
  phoneNumber: {
    id: 'primaryContact.phoneNumber',
    defaultMessage: 'Phone number',
  },
  phoneNumberCountryCode: {
    id: 'primaryContact.countryCode',
    defaultMessage: 'Country code',
  },
  selectStatePlaceholder: {
    id: 'primaryContact.selectStatePlaceholder',
    defaultMessage: 'Select a state',
  },
  address: {
    id: 'primaryContact.address',
    defaultMessage: 'Address',
  },
  previewTitle: {
    id: 'primaryContact.previewTitle',
    defaultMessage: 'Preview to submit',
  },
  previewDescription: {
    id: 'primaryContact.previewDescription',
    defaultMessage:
      'Please check and confirm the information you filled is correct to save as the average verification time is around a week.',
  },
  profileAddressBannerBold: {
    id: 'profileAddress.bannerBold',
    defaultMessage: '{appName} is currently only accepting payment in the United States',
  },
  profileAddressBanner: {
    id: 'profileAddress.banner',
    defaultMessage: 'if you do not have a payment address inside the US, please signup for the',
  },
  profileAddressBannerWaitlist: {
    id: 'profileAddress.bannerWaitlist',
    defaultMessage: 'waitlist',
  },
  phoneVerificationMethodSms: {
    id: 'phoneVerificationMethod.sms',
    defaultMessage: 'Text me at {phone}',
  },
  phoneVerificationMethodCall: {
    id: 'phoneVerificationMethod.call',
    defaultMessage: 'Call me at {phone}',
  },
  phoneVerificationDialogTitle: {
    id: 'phoneVerificationDialog.title',
    defaultMessage: 'Phone verification',
  },
  emailVerificationDialogTitle: {
    id: 'emailVerificationDialog.title',
    defaultMessage: 'Email verification',
  },
  phoneVerificationDialogChoiceGroupLabel: {
    id: 'phoneVerificationDialog.choiceGroupLabel',
    defaultMessage: 'How would you like to verify?',
  },
  phoneVerificationDialogNextButton: {
    id: 'phoneVerificationDialog.nextButton',
    defaultMessage: 'Next',
  },
  phoneVerificationDialogCancelButton: {
    id: 'phoneVerificationDialog.cancelButton',
    defaultMessage: 'Cancel',
  },
  phoneVerificationDialogVerifyButton: {
    id: 'phoneVerificationDialog.verifyButton',
    defaultMessage: 'Verify',
  },
  phoneVerificationDialogBody: {
    id: 'phoneVerificationDialog.body',
    defaultMessage: 'Message and data rates may apply. By selecting Next, you agree to the {terms} and {privacy}',
  },
  phoneVerificationDialogTermsAndConditions: {
    id: 'phoneVerificationDialog.termsAndCondition',
    defaultMessage: 'Terms and Conditions',
  },
  phoneVerificationDialogPrivacy: {
    id: 'phoneVerificationDialog.privacy',
    defaultMessage: 'Privacy statement',
  },
  phoneVerificationDialogResendCode: {
    id: 'phoneVerificationDialog.resendCode',
    defaultMessage: 'Resend code',
  },
  phoneVerificationDialogCallAgain: {
    id: 'phoneVerificationDialog.callAgain',
    defaultMessage: 'Call again',
  },
  emailVerificationDialogCodeSent: {
    id: 'emailVerificationDialog.codeSent',
    defaultMessage: 'A confirmation code was sent to {email}. Please enter the code below.',
  },
  phoneVerificationDialogSmsText: {
    id: 'phoneVerificationDialog.smsText',
    defaultMessage: 'A confirmation code was sent to {phoneNumber}. Please enter the code below.',
  },
  phoneVerificationDialogCallText: {
    id: 'phoneVerificationDialog.callText',
    defaultMessage: 'We are calling {phoneNumber} with the necessary code. Please enter the code below.',
  },
  verificationDialogPlaceholder: {
    id: 'verificationDialog.placeholder',
    defaultMessage: 'Enter code',
  },
  phoneVerificationDialogSuccess: {
    id: 'phoneVerificationDialog.success',
    defaultMessage: 'Your phone number {phoneNumber} is verified',
  },
  emailVerificationDialogSuccess: {
    id: 'emailVerificationDialog.success',
    defaultMessage: 'Your email {email} is verified.',
  },
  phoneVerificationDialogSuccessAlt: {
    id: 'phoneVerificationDialog.successAlt',
    defaultMessage: 'phone verified',
  },
  profileInvalidFieldsErrorMessage: {
    id: 'profileInvalidField.errorMessage',
    defaultMessage: 'You have { count } invalid fields, please correct them to continue.',
  },
  profileInvalidAddressErrorMessage: {
    id: 'profileInvalidAddress.errorMessage',
    defaultMessage: 'Invalid address. The postal code provided does not match the country, city or the state/province.',
  },
  profileAddressValidationFailed: {
    id: 'profileAddressValidation.failed',
    defaultMessage: 'Failed to validate user address. Please try again!',
  },
  generateVerificationCodeFailed: {
    id: 'generateVerificationCode.failed',
    defaultMessage: 'Failed to generate phone verification code. Please try again!',
  },
  generateEmailVerificationCodeLimitReached: {
    id: 'generateEmailVerificationCodeLimitReached',
    defaultMessage: "Didn't receive the email?",
  },
  generatePhoneVerificationCodeLimitReached: {
    id: 'generatePhoneVerificationCodeLimitReached',
    defaultMessage:
      "You've reached the maximum number of attempts to resend the code. Please wait for ten minutes before requesting another code.",
  },
  generateEmailVerificationCodeFailed: {
    id: 'generateVerificationCode.failed',
    defaultMessage: 'Failed to generate email verification code. Please try again!',
  },
  getVerificationStatusFailed: {
    id: 'getVerificationStatus.failed',
    defaultMessage: 'Failed to check email verification status.',
  },
  verifyingGeneratedCodeFailed: {
    id: 'verifyingGeneratedCode.failed',
    defaultMessage: 'Failed to verify generated code. Please try again!',
  },
  generatedCodeInvalid: {
    id: 'generatedCode.invalid',
    defaultMessage: 'This code is invalid. Please try again.',
  },
  generatedCodeExpired: {
    id: 'generatedCode.expired',
    defaultMessage: 'This code is expired. Please try again.',
  },
  generatedCodeFailed: {
    id: ' generatedCode.failed',
    defaultMessage: 'Failed to send verification code. Please try again',
  },
  publisherProfileDetailsFailed: {
    id: 'publisherProfileDetails.failed',
    defaultMessage: 'We’re unable to show your publisher profile details. Please try again later.',
  },
  updatePublisherProfileDetailsFailed: {
    id: 'updatePublisherProfileDetails.failed',
    defaultMessage: 'We’re unable to update your publisher profile details. Please try again later.',
  },
  loadUserDetailsFailed: {
    id: 'loadUserDetails.failed',
    defaultMessage: 'We’re unable to load your user details at this time. Please try again later.',
  },
  failedToLoadPublisherDetails: {
    id: 'publisherProfileDetails.failedToLoadPublisherDetails',
    defaultMessage: 'Failed to load publisher details',
  },
  failedToLoadProfileDetails: {
    id: 'publisherProfileDetails.failedToLoadProfileDetails',
    defaultMessage: 'Failed to load profile details',
  },
});
