import { defineMessages } from 'react-intl';

export default defineMessages({
  profileCardTitleNotStarted: {
    id: 'profileCardNotStarted.title',
    defaultMessage: 'Create your profile',
  },
  profileCardDescriptionNotStarted: {
    id: 'profileCardNotStarted.description',
    defaultMessage: 'We’ll need to verify your identity as an individual or business before displaying ads on your site.',
  },
  profileCardButtonNotStarted: {
    id: 'profileCardNotStarted.button',
    defaultMessage: 'Create your profile',
  },
  siteCardTitleNotStarted: {
    id: 'siteCardNotStarted.title',
    defaultMessage: 'Connect your site',
  },
  siteCardDescriptionNotStarted: {
    id: 'siteCardNotStarted.description',
    defaultMessage: 'Connect your site by adding its top level domain.',
  },
  siteCardButtonNotStarted: {
    id: 'siteCardNotStarted.button',
    defaultMessage: 'Connect your site',
  },
  adUnitCardTitleNotStarted: {
    id: 'adUnitCardNotStarted.title',
    defaultMessage: 'Create an ad unit',
  },
  adUnitCardDescriptionNotStarted: {
    id: 'adUnitCardNotStarted.description',
    defaultMessage: 'Customize the way ads will display on your website.',
  },
  adUnitCardButtonNotStarted: {
    id: 'adUnitCardNotStarted.button',
    defaultMessage: 'Create Ad Unit',
  },
  profileCardTitleAwaitingForVerification: {
    id: 'profileCard.title',
    defaultMessage: 'Your profile is awaiting verification',
  },
  profileCardDescriptionAwaitingForVerification: {
    id: 'profileCard.description',
    defaultMessage:
      "Thank you for submitting your profile for review. We'll complete the review within seven business days, and once the review is complete, we'll notify you by email.",
  },
  profileCardButtonAwaitingForVerification: {
    id: 'profileCardAwaitingForVerification.button',
    defaultMessage: 'View your profile',
  },
  siteCardTitleAwaitingForVerification: {
    id: 'siteCard.title',
    defaultMessage: 'Site in review',
  },
  siteCardDescriptionAwaitingForVerification: {
    id: 'siteCard.description',
    defaultMessage:
      "Thank you for submitting your site for review. We'll notify you by email when the review is complete. Please note that it usually takes 7 days for a site to be reviewed. In the meantime, you can continue to submit more sites to increase your earnings.",
  },
  siteCardButtonAwaitingForVerification: {
    id: 'siteCardAwaitingForVerification.button',
    defaultMessage: 'Add more sites',
  },
  profileCardTitleCompleted: {
    id: 'profileCard.title',
    defaultMessage: 'Profile setup complete',
  },
  profileCardDescriptionCompleted: {
    id: 'profileCard.description',
    defaultMessage: 'We verified your data and approved you as a publisher.',
  },
  profileCardButtonCompleted: {
    id: 'profileCardCompleted.button',
    defaultMessage: 'View your profile',
  },
  siteCardTitleCompleted: {
    id: 'siteCard.title',
    defaultMessage: 'Site setup complete',
  },
  siteCardDescriptionCompleted: {
    id: 'siteCard.description.cardCompleted',
    defaultMessage:
      'We’ve verified and approved your site. In the meantime, you can continue to submit more sites to increase your earnings. ',
  },
  siteCardButtonCompleted: {
    id: 'siteCardCompleted.button',
    defaultMessage: 'Add more sites',
  },
  adUnitCardTitleCompleted: {
    id: 'adUnitCard.title',
    defaultMessage: 'Ad unit setup complete',
  },
  adUnitCardDescriptionCompleted: {
    id: 'adUnitCard.description',
    defaultMessage: 'You’ve set up your ad unit. Adding more ad units may help you earn more with your site.',
  },
  adUnitCardButtonCompleted: {
    id: 'adUnitsCardCompleted.button',
    defaultMessage: 'Add more ad units',
  },
  setupProfile: {
    id: 'setup.profile',
    defaultMessage: 'Profile Setup',
  },
  setupSite: {
    id: 'setup.site',
    defaultMessage: 'Site Setup',
  },
  setupAdunit: {
    id: 'setup.adunit',
    defaultMessage: 'Ad unit Setup',
  },
  NotStarted: {
    id: 'homepageStatus.notStarted',
    defaultMessage: 'Not started',
  },
  AwaitingVerification: {
    id: 'homepageStatus.awaitingVerification',
    defaultMessage: 'Awaiting verification',
  },
  Completed: {
    id: 'homepageStatus.completed',
    defaultMessage: 'Completed',
  },
  Rejected: {
    id: 'homepageStatus.rejected',
    defaultMessage: 'Rejected',
  },
});
