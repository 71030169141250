import { INamePair } from '../@data';
import { HomePageStatus } from '../pages/ad-management/Home/@data/store/schema/IHomeViewState';
import { getStore } from '../pages/ad-management/Home/@data/store/store';

export function getFullName(name?: INamePair): string {
  if (!name) {
    return '';
  }

  const { firstName, lastName } = name;
  return `${firstName} ${lastName}`;
}

export function isProfileRejected() {
  return getStore().profileStatus === HomePageStatus.Rejected;
}
