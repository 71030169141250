import { MessageBarButton, MessageBarType } from '@fluentui/react';
import React from 'react';
import { isPublisherContactRejected, isPublisherRejected } from '../../@data';
import appMessages from '../../App.messages';
import { ExternalLink } from '../../components/ExternalLink';
import { INotificationBarItem, NotificationBar } from '../../components/NotificationBar';
import { TNC_HREF_PATH } from '../../constants/AppConstants';
import { NullableJSXElement } from '../../types';
import { getAdManagementStore } from './@data';
import {
  setPublisherRejectedMessageClosedAction,
  setPublisherWarningMessageClosedAction,
} from './@data/actions/entityEvaluationMutatorActions';
import { getClassNames } from './AdManagement.styles';
import profileCardMessages from './Home/components/ProfileCard/ProfileCard.messages';
import { isProfileInEditMode } from './Profile/@data';

export function renderCustomNotifications(): NullableJSXElement {
  const pageStore = getAdManagementStore();
  const { messageBarButtonStyle, linkStyle } = getClassNames();
  const { formatMessage } = this.props.intl;

  const notifications: INotificationBarItem[] = [];

  if (isPublisherRejected() && !pageStore.isPublisherRejectedMessageClosed) {
    notifications.push({
      children: (
        <div>
          {formatMessage(profileCardMessages.rejectedBannerPart1)}
          <ExternalLink href={TNC_HREF_PATH} className={linkStyle}>
            {formatMessage(profileCardMessages.termsAndConditions)}
          </ExternalLink>
          .&nbsp;
          {/* Removing this message as we don't have publisher deletion flow in place yet.
          {formatMessage(profileCardMessages.rejectedBannerPart2)}
          */}
        </div>
      ),
      onDismiss: () => setPublisherRejectedMessageClosedAction(true),
      messageBarType: MessageBarType.error,
      suppressAutoDismiss: true,
    });
  }

  if (isPublisherContactRejected() && !pageStore.isPublisherWarningMessageClosed && !isProfileInEditMode()) {
    notifications.push({
      children: (
        <div>
          <strong>{formatMessage(profileCardMessages.retryTitle)}</strong>&nbsp;
          {formatMessage(profileCardMessages.retryDescription)}
          <MessageBarButton onClick={this._handleProfileEditButtonClick} className={messageBarButtonStyle}>
            {formatMessage(appMessages.edit)}
          </MessageBarButton>
        </div>
      ),
      onDismiss: () => setPublisherWarningMessageClosedAction(true),
      messageBarType: MessageBarType.warning,
      suppressAutoDismiss: true,
    });
  }

  return (
    <NotificationBar
      items={notifications}
      intl={this.props.intl}
      onRemoved={() => {
        return;
      }}
    />
  );
}
