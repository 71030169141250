import { InjectedIntl } from 'react-intl';
import { action } from 'satcheljs';
import { INotificationCardItem } from '../../components/NotificationCard';

export const onNotificationCardAdded = action('onNotificationCardAdded', (notification: INotificationCardItem) => ({
  notification,
}));

export const onNotificationCardRemoved = action('onNotificationCardRemoved', (id: string) => ({
  id,
}));

export const onDismissAllNotifications = action('onDismissAllNotifications');

export const fetchBackendNotifications = action('fetchBackendNotifications', (intl: InjectedIntl) => ({
  intl,
}));

export const onNotificationPanelItemsChanged = action('onNotificationPanelItemsChanged', (value: INotificationCardItem[]) => ({
  value,
}));

export const onDismissNotification = action('onDismissNotification', (notification: INotificationCardItem, intl: InjectedIntl) => ({
  notification,
  intl,
}));

export const onNotificationPanelClosed = action('onNotificationPanelClosed', (isClosed: boolean) => ({ isClosed }));
