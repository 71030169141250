import { FluentTheme, IFontStyles, initializeIcons, IPalette, ISpacing, ITheme, loadTheme, registerIcons } from '@fluentui/react';

export interface IPubcenterPalette extends Partial<IPalette> {
  messageBarError: string;
  severeError: string;
  error: string;
  warning: string;
  success: string;
  headerGlobal: string;
  antiqueBronze: string;
  seaGreen: string;
  lowContrastBlue: string;
  disabledGray: string;
  eerieBlack: string;
  highContrastPrimary: string;
  highContrastLink: string;
  raisinBlack: string;
  grayX11: string;
  gray: string;
  neutralTertiaryWhiteContrast: string;
  neutralSecondaryAltWhiteContrast: string;
  taupeGray: string;
  darkSilver: string;
  frenchBlue: string;
  azureishWhite: string;
  paleChestnut: string;
  lavenderBlush: string;
  venetianRed: string;
  honeydew: string;
  grannySmithApple: string;
  deepGreen: string;
  neutralForeground1Rest: string;
  neutralForeground2Rest: string;
  neutralForeground3Rest: string;
  brandForeground1Rest: string;
  statusSuccessForeground1Rest: string;
  neutralBackground1Selected: string;
  neutralBackground4Rest: string;
  statusSuccessBackground1Rest: string;
  neutralStroke2Rest: string;
  statusSuccessStroke1Rest: string;
  ['NeutralBackground4.Rest']: string;
  ['NeutralForeground3.Rest']: string;
  ['NeutralForeground2.Rest']: string;
  ['NeutralForeground1.Rest']: string;
  ['CompoundBrandForeground1.Rest']: string;
  ['CompoundBrandForeground1.Hover']: string;
  ['NeutralBackground3.Rest']: string;
  ['NeutralBackground5.Rest']: string;
  ['NeutralStroke1.Rest']: string;
  ['BrandBackground.Rest']: string;
  ['BrandBackground2.Rest']: string;
}

export interface IPubcenterBoxShadow {
  soft: string;
  aesthetic: string;
  creative: string;
  raised: string;
}

export interface IPubcenterSpacing extends Partial<ISpacing> {
  size0: string;
  size1: string;
  size2: string;
  size3: string;
  size4: string;
  size5: string;
  size6: string;
  size7: string;
  size8: string;
  size10: string;
  size12: string;
  size14: string;
  size15: string;
  size16: string;
  size18: string;
  size20: string;
  size22: string;
  size24: string;
  size28: string;
  size32: string;
  size30: string;
  size36: string;
  size40: string;
  size44: string;
  size46: string;
  size48: string;
  size50: string;
  size52: string;
  size54: string;
  size56: string;
  size60: string;
  size64: string;
  size72: string;
  size80: string;
  size92: string;
  size96: string;
  size100: string;
  size120: string;
  size126: string;
  size128: string;
  size142: string;
  size146: string;
  size150: string;
  size159: string;
  size160: string;
  size180: string;
  size192: string;
  size200: string;
  size220: string;
  size250: string;
  size256: string;
  size260: string;
  size300: string;
  size320: string;
  size350: string;
  size360: string;
  size480: string;
  size500: string;
  size600: string;
  size896: string;
  size960: string;
}

interface IPubCenterFont {
  fontSize: string;
  lineHeight: string;
  fontWeight?: string;
}
export interface IPubCenterFonts extends Partial<IFontStyles> {
  size10: IPubCenterFont;
  size12: IPubCenterFont;
  size14: IPubCenterFont;
  size14Medium: IPubCenterFont;
  size16: IPubCenterFont;
  size18: IPubCenterFont;
  size20: IPubCenterFont;
  size24: IPubCenterFont;
  size28: IPubCenterFont;
  size32: IPubCenterFont;
  size42: IPubCenterFont;
}

export interface IPubcenterTheme {
  palette: IPubcenterPalette;
  spacing: IPubcenterSpacing;
  fonts: IPubCenterFonts;
  boxShadow: IPubcenterBoxShadow;
}

const spacing: IPubcenterSpacing = {
  size0: '0rem',
  size1: '0.0625rem',
  size2: '0.125rem',
  size3: '0.1875rem',
  size4: '0.25rem',
  size5: '0.3125rem',
  size6: '0.375rem',
  size7: '0.4375rem',
  size8: '0.50rem',
  size10: '0.625rem',
  size12: '0.75rem',
  size14: '0.875rem',
  size15: '0.938rem',
  size16: '1.0rem',
  size18: '1.125rem',
  size20: '1.25rem',
  size22: '1.375 rem',
  size24: '1.5rem',
  size28: '1.75rem',
  size30: '1.875rem',
  size32: '2.0rem',
  size36: '2.25rem',
  size40: '2.50rem',
  size44: '2.75rem',
  size46: '2.875rem',
  size48: '3.0rem',
  size50: '3.125rem',
  size52: '3.25rem',
  size54: '3.375rem',
  size56: '3.5rem',
  size60: '3.75rem',
  size64: '4.0rem',
  size72: '4.5rem',
  size80: '5.0rem',
  size92: '5.75rem',
  size96: '6.0rem',
  size100: '6.25rem',
  size120: '7.5rem',
  size126: '7.875rem',
  size128: '8.0rem',
  size142: '8.875rem',
  size146: '9.125rem',
  size150: '9.375rem',
  size159: '9.9375rem',
  size160: '10rem',
  size180: '11.25rem',
  size192: '12rem',
  size200: '12.5rem',
  size220: '13.75rem',
  size250: '15.625rem',
  size256: '16.0rem',
  size260: '16.25rem',
  size300: '18.75rem',
  size320: '20.0rem',
  size350: '21.875rem',
  size360: '22.5rem',
  size480: '30.0rem',
  size500: '31.25rem',
  size600: '37.5rem',
  size896: '56.0rem',
  size960: '60.0rem',
};

const palette: IPubcenterPalette = {
  headerGlobal: '#2f2f2f',
  themeLighterAlt: '#EFF6FC',
  themeLighter: '#DEECF9',
  themeLight: '#C7E0F4',
  themePrimary: '#0078D4',
  themeTertiary: '#71afe5',
  themeSecondary: '#2b88d8',
  themeDarkAlt: '#106EBE',
  themeDark: '#005A9E',
  themeDarker: '#004578',
  neutralLighterAlt: '#FAF9F8',
  neutralLighter: '#F3F2F1',
  neutralLight: '#EDEBE9',
  neutralQuaternaryAlt: '#E1DFDD',
  neutralQuaternary: '#D2D0CE',
  neutralTertiaryAlt: '#C8C6C4',
  neutralTertiary: '#A19F9D',
  neutralSecondary: '#605E5C',
  neutralPrimaryAlt: '#3B3A39',
  neutralPrimary: '#323130',
  neutralDark: '#201F1E',
  black: '#000000',
  white: '#fff',
  redDark: '#a80000',
  blue: '#0078D7',
  blueDark: '#004578',
  messageBarError: '#FDE7E9',
  severeError: '#FED9CC',
  warning: '#fff4ce',
  success: '#DFF6DD',
  error: '#d83b01',
  antiqueBronze: '#7A6816',
  seaGreen: '#218D51',
  lowContrastBlue: '#0072cc',
  disabledGray: '#727272',
  eerieBlack: '#1A1A1A',
  highContrastPrimary: '#0072c9',
  highContrastLink: '#085088',
  raisinBlack: '#242424',
  grayX11: '#bbbbbb',
  gray: '#808080',
  neutralTertiaryWhiteContrast: '#737271',
  neutralSecondaryAltWhiteContrast: '#83817f',
  taupeGray: '#8A8886',
  darkSilver: '#6E6E6E',
  frenchBlue: '#036AC4',
  azureishWhite: '#e2e8f0',
  paleChestnut: '#EEACB2',
  lavenderBlush: '#FDF3F4',
  venetianRed: '#CC1512',
  honeydew: '#F1FAF1',
  grannySmithApple: '#9FD89F',
  deepGreen: '#0E700E',
  neutralForeground1Rest: '#242424',
  neutralForeground2Rest: '#424242',
  neutralForeground3Rest: '#616161',
  neutralBackground1Selected: '#EBEBEB',
  neutralBackground4Rest: '#F0F0F0',
  neutralStroke2Rest: '#E0E0E0',
  brandForeground1Rest: '#0F6CBD',
  statusSuccessBackground1Rest: '#F1FAF1',
  statusSuccessForeground1Rest: '#0E700E',
  statusSuccessStroke1Rest: '#9FD89F',
  'NeutralForeground2.Rest': '#424242',
  'NeutralForeground1.Rest': '#242424',
  'NeutralBackground4.Rest': '#F0F0F0',
  'CompoundBrandForeground1.Rest': '#0F6CBD',
  'CompoundBrandForeground1.Hover': '#115EA3',
  'NeutralBackground3.Rest': '#F5F5F5',
  'NeutralBackground5.Rest': '#EBEBEB',
  'NeutralStroke1.Rest': '#D1D1D1',
  'BrandBackground.Rest': '#0F6CBD',
  'BrandBackground2.Rest': '#EBF3FC',
  'NeutralForeground3.Rest': '#616161',
};

const boxShadow = {
  soft: `0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11)`,
  aesthetic: `0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11)`,
  creative: `0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)`,
  raised: `0 26px 58px 0 rgba(0, 0, 0, .22), 0 5px 14px 0 rgba(0, 0, 0, .18)`,
};

initializeIcons();

// TODO: Remove this after we migrate icons to Fluent icons.
registerIcons({
  fontFace: {
    fontFamily: `"Microsoft Advertising MDL2 Assets"`,
  },
  icons: {
    AdUnit: '\uF7F2',
    AdUnitChannel: '\uF7F5',
    AdUnitBlock: '\uF7F3',
    Notifications: '\uEA8F',
    ShowAll: '\uE890',
  },
});

/**
 * We should have a consistent typography across the pages. Generally, all font sizes comes with recommended
 * line-height values. Here, we have added the standard line-height values for each font size by referring
 * https://developer.microsoft.com/en-us/fluentui#/styles/web/typography#sizes guidelines.
 *
 * In some cases, font-weights also go hand-in-hand with font-sizes. But in the design language we use in
 * figma has different font-weights for the same font-size. Hence they are not part of these fonts.
 */
const fonts: IPubCenterFonts = {
  size10: { fontSize: '10px', lineHeight: '14px' },
  size12: { fontSize: '12px', lineHeight: '16px' },
  size14: { fontSize: '14px', lineHeight: '20px' },
  size14Medium: { fontSize: '14px', lineHeight: '22px', fontWeight: '600' },
  size16: { fontSize: '16px', lineHeight: '22px' },
  size18: { fontSize: '18px', lineHeight: '24px' },
  size20: { fontSize: '20px', lineHeight: '28px' },
  size24: { fontSize: '24px', lineHeight: '32px' },
  size28: { fontSize: '28px', lineHeight: '36px' },
  size32: { fontSize: '32px', lineHeight: '40px' },
  size42: { fontSize: '42px', lineHeight: '42px' },
};

export const PubcenterTheme = {
  palette,
  spacing,
  fonts,
  boxShadow,
};
FluentTheme.palette = { ...FluentTheme.palette, ...PubcenterTheme.palette };
FluentTheme.spacing = { ...FluentTheme.spacing, ...PubcenterTheme.spacing };
FluentTheme.fonts = { ...FluentTheme.fonts, ...PubcenterTheme.fonts };
(FluentTheme as ITheme & { boxShadow: IPubcenterBoxShadow }).boxShadow = { ...PubcenterTheme.boxShadow };

// It's recommended to not use loadTheme, instead use ThemeProvider
// Refer: https://github.com/microsoft/fluentui/blob/master/packages/react/src/utilities/ThemeProvider/README.md#loadtheme
// TODO: Remove usage of loadTheme
loadTheme(FluentTheme);

export const PubcenterCustomizations = FluentTheme;

export enum BreakPoints {
  ResponsiveExtraSmall = '@media(max-width: 320px)',
  ResponsiveSmall = '@media(max-width: 640px)',
  ResponsiveNonSmall = '@media(min-width: 641px)',
  ResponsiveMedium = '@media screen and (min-width: 641px) and (max-width: 1024px)',
  ResponsiveLarge = '@media(min-width: 1025px) and (max-width: 1600px)',
  ResponsiveExtraLarge = '@media(min-width: 1601px)',
}

export enum AccessibilityContrast {
  Activated = '@media screen and (-ms-high-contrast: active), screen and (forced-colors: active)',
}
