import { IProcessedStyleSet, classNamesFunction, getTheme } from '@fluentui/react';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { APP_NAME_PUBCENTER, APP_NAME_SHORT } from '../../constants';
import { isLocationPubCenter } from '../../utils';
import { isProfileRejected } from '../../utils/fieldUtils';
import { getStyles } from './Logo.styles';
import { ILogoStyleProps, ILogoStyles } from './Logo.types';
import messages from './messages';
import microsoftLogo from './resources/microsoft.svg';
import microsoftDarkLogo from './resources/microsoftDark.svg';
import microsoftGreyLogo from './resources/microsoftGrey.png';

const getClassNames = classNamesFunction<ILogoStyleProps, ILogoStyles>();
type LogoProps = { shouldRenderLogo?: boolean; renderDarkLogo?: boolean; renderGreyLogo?: boolean };

export const Logo = injectIntl<InjectedIntlProps & LogoProps>(
  ({ shouldRenderLogo = true, renderDarkLogo = false, intl: { formatMessage }, renderGreyLogo = true }) => {
    const _classNames: IProcessedStyleSet<ILogoStyles> = React.useMemo(
      () =>
        getClassNames(getStyles, {
          theme: getTheme(),
          shouldRenderLogo: shouldRenderLogo,
          renderDarkLogo: renderDarkLogo,
          renderGreyLogo: renderGreyLogo,
        }),
      [shouldRenderLogo, renderDarkLogo, renderGreyLogo]
    );
    const { logo: logoStyle, logoNavButton, divider } = _classNames;
    const logo = renderGreyLogo ? microsoftGreyLogo : renderDarkLogo ? microsoftDarkLogo : microsoftLogo;

    return (
      <div className={logoStyle}>
        {shouldRenderLogo && <img src={logo} alt={formatMessage(messages.msLogo)} />}
        {shouldRenderLogo && <div className={divider}>|</div>}

        <div className={!isProfileRejected() ? logoNavButton : undefined} />
        <span>{isLocationPubCenter() ? APP_NAME_PUBCENTER : APP_NAME_SHORT}</span>
      </div>
    );
  }
);
